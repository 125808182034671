import { ReactNode, useState } from 'react';
import { FormInstance } from 'antd';
import { isNil } from 'ramda';

import { Organizations } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import { formValidateFields } from '@utils/antd-form.util';
import { patchOneOrganization } from '@apis/organizations';
import { FormValues } from '../interfaces';

interface UseEditOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useEdit = (props: UseEditOptions = {}) => {
  const { onSuccess, onError, throwable } = props;
  const [loading, setLoading] = useState(false);

  const submit = async (
    orgId: string,
    form: FormInstance<FormValues>,
    originGlobalAcctRoles: Organizations.OrgAcctRole[] = [],
  ) => {
    return await asyncLoadingWrapper(
      setLoading,
      async () => {
        const [data, error] = await formValidateFields(form);
        if (!isNil(error) || isNil(data)) return false;
        const { accountRoles, managers, ...otherData } = data;

        const originGlobalAcctRoleIds = originGlobalAcctRoles.map(
          ({ globalAccountRoleId }) => globalAccountRoleId,
        );
        const [addGlobalAcctRoleIds, unchangingGlobalAcctRoleIds] = (
          accountRoles || []
        ).reduce(
          (result, addAcctRole) => {
            if (!originGlobalAcctRoleIds.includes(addAcctRole)) {
              result[0] = [...result[0], addAcctRole];
            } else {
              result[1] = [...result[1], addAcctRole];
            }
            return result;
          },
          [[], []] as [
            Required<Organizations.OrgAcctRole>['globalAccountRoleId'][],
            Organizations.OrgAcctRole['globalAccountRoleId'][],
          ],
        );
        const removeOrgAcctRoleIds = originGlobalAcctRoles
          .filter(
            ({ globalAccountRoleId }) =>
              !unchangingGlobalAcctRoleIds.includes(globalAccountRoleId),
          )
          .map(({ id }) => id);

        const { add, remove } = managers || {};
        const addManagers = add?.map((manager) => ({
          id: manager.id,
        }));
        const removeManagerOrgIds = remove?.map(
          (manager) => manager.mgrOrgId as string,
        );

        await patchOneOrganization(orgId, {
          ...otherData,
          addGlobalAcctRoleIds,
          removeOrgAcctRoleIds,
          addManagers,
          removeManagerOrgIds,
        });
        onSuccess?.('Successfully updated.');
        return true;
      },
      (error) => {
        onError?.(error);
        return false;
      },
      throwable,
    );
  };

  return { loading, submit };
};

export default useEdit;
