import { CarOutlined, UserOutlined } from '@ant-design/icons';
import { i18n, TFunction } from 'i18next';
import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTERS_MANAGER } from '@i18n/namespaces';
import { NexusRouteObject } from '@interfaces/routes.interface';
import { excludeNoPathAndFlatRoute } from '@utils/router.util';
import { lazy } from './utils';

import ErrorBoundary from '@pages/common/ErrorBoundary';

const ManagerWorkspace = lazy(() => import('@templates/ManagerWorkspace'));
const ManagerLogin = lazy(() => import('@pages/managers/Login'));
const ManagerInvitation = lazy(() => import('@pages/managers/Invitation'));
const ManagerForgetPassword = lazy(
  () => import('@pages/managers/ForgetPassword'),
);
const ManagerResetPassword = lazy(
  () => import('@pages/managers/ResetPassword'),
);
const ManagerAccountList = lazy(() => import('@pages/managers/AccountList'));
const ManagerAccountEditor = lazy(
  () => import('@pages/managers/AccountEditor'),
);
const ManagerAccountRole = lazy(() => import('@pages/managers/AccountRole'));
const ManagerAccountRoleEditor = lazy(
  () => import('@pages/managers/AccountRoleEditor'),
);
const FleetTrackerOverview = lazy(
  () => import('@pages/managers/fleet-tracker/Overview'),
);
const FleetTrackerAsset = lazy(
  () => import('@pages/managers/fleet-tracker/Asset'),
);
const FleetTrackerVehicleDetail = lazy(
  () => import('@pages/managers/fleet-tracker/VehicleDetail'),
);
const FleetTrackerVehicleEditor = lazy(
  () => import('@pages/managers/fleet-tracker/VehicleEditor'),
);
const FleetTrackerVehicleBuilder = lazy(
  () => import('@pages/managers/fleet-tracker/VehicleBuilder'),
);
const FleetTrackerLocationHistory = lazy(
  () => import('@pages/managers/fleet-tracker/LocationHistory'),
);
const FleetTrackerTracking = lazy(
  () => import('@pages/managers/fleet-tracker/Tracking'),
);
const RentourBilling = lazy(() => import('@pages/managers/rentour/Billing'));
const RentourReservationDashboard = lazy(
  () => import('@pages/managers/rentour/ReservationDashboard'),
);
const RentourReservationManagement = lazy(
  () => import('@pages/managers/rentour/ReservationManagement'),
);
const RentourReservationEditor = lazy(
  () => import('@pages/managers/rentour/ReservationEditor'),
);
const RentourReservationBuilder = lazy(
  () => import('@pages/managers/rentour/ReservationBuilder'),
);
const RentourVehicleManagement = lazy(
  () => import('@pages/managers/rentour/VehicleManagement'),
);
const RentourVehicleControl = lazy(
  () => import('@pages/managers/rentour/VehicleControl'),
);
const PricingConfigList = lazy(
  () => import('@/pages/managers/pricing/ConfigList'),
);
const PricingConfigBuilder = lazy(
  () => import('@/pages/managers/pricing/ConfigBuilder'),
);
const PricingConfigPreview = lazy(
  () => import('@/pages/managers/pricing/ConfigPreview'),
);
const PricingConfigHistory = lazy(
  () => import('@/pages/managers/pricing/ConfigHistory'),
);
const PricingConfigEditor = lazy(
  () => import('@/pages/managers/pricing/ConfigEditor'),
);
const PricingConfigVehiclesDelete = lazy(
  () => import('@/pages/managers/pricing/ConfigVehicles/Delete'),
);
const PricingConfigVehiclesPreview = lazy(
  () => import('@/pages/managers/pricing/ConfigVehicles/Preview'),
);
const PricingConfigVehiclesAdd = lazy(
  () => import('@/pages/managers/pricing/ConfigVehicles/Add'),
);

export const HOME_PAGE_PATH = '';

export const createManagerWorkspaceRoutes = (
  t: TFunction<typeof ROUTERS_MANAGER>,
  i18n: i18n,
): NexusRouteObject[] => [
  {
    path: HOME_PAGE_PATH,
    element: 'Welcome to Nexus',
    label: t('routers.manager:home'),
  },
  {
    path: 'account',
    label: t('routers.manager:account'),
    icon: <UserOutlined />,
    children: [
      {
        path: 'account-list',
        label: t('routers.manager:account-list'),
        authority: 'Account List',
        lazy: ManagerAccountList,
        children: [
          {
            path: 'editor/:mgrId',
            label: t('routers.manager:account-list'),
            authority: 'Account Editor',
            lazy: ManagerAccountEditor,
            excludeMenuItem: true,
            fallback: true,
          },
        ],
      },
      {
        path: 'account-roles',
        label: t('routers.manager:account-roles'),
        authority: 'Account Roles',
        lazy: ManagerAccountRole,
        children: [
          {
            path: 'editor/:orgAcctRoleId?',
            label: t('routers.manager:account-roles'),
            authority: 'Account Role Editor',
            lazy: ManagerAccountRoleEditor,
            excludeMenuItem: true,
            fallback: true,
          },
        ],
      },
    ],
  },
  {
    path: 'fleet-tracker',
    label: t('routers.manager:fleet-tracker'),
    icon: <CarOutlined />,
    children: [
      {
        path: 'overview',
        label: t('routers.manager:fleet-tracker-overview'),
        authority: 'Fleet Tracker Overview',
        lazy: FleetTrackerOverview,
        children: [
          {
            path: 'vehicle/detail/:vehicleId',
            label: t('routers.manager:fleet-tracker-overview'),
            authority: 'Fleet Tracker Vehicle Detail',
            lazy: FleetTrackerVehicleDetail,
            excludeMenuItem: true,
            fallback: true,
          },
          {
            path: 'vehicle/editor/:vehicleId',
            label: t('routers.manager:fleet-tracker-overview'),
            authority: 'Fleet Tracker Vehicle Editor',
            lazy: FleetTrackerVehicleEditor,
            excludeMenuItem: true,
            fallback: true,
          },
          {
            path: 'vehicle/builder',
            label: t('routers.manager:fleet-tracker-overview'),
            authority: 'Fleet Tracker Vehicle Builder',
            lazy: FleetTrackerVehicleBuilder,
            excludeMenuItem: true,
            fallback: true,
          },
        ],
      },
      {
        path: 'assets',
        label: t('routers.manager:fleet-tracker-assets'),
        authority: 'Fleet Tracker Assets',
        lazy: FleetTrackerAsset,
        children: [
          {
            path: 'vehicle/editor/:vehicleId',
            label: t('routers.manager:fleet-tracker-assets'),
            authority: 'Fleet Tracker Vehicle Editor',
            loader() {
              return { onlyInfo: true };
            },
            lazy: FleetTrackerVehicleEditor,
            excludeMenuItem: true,
            fallback: true,
          },
          {
            path: 'vehicle/builder',
            label: t('routers.manager:fleet-tracker-assets'),
            authority: 'Fleet Tracker Vehicle Builder',
            loader() {
              return { onlyInfo: true };
            },
            lazy: FleetTrackerVehicleBuilder,
            excludeMenuItem: true,
            fallback: true,
          },
        ],
      },
      {
        path: 'location-history',
        label: t('routers.manager:fleet-tracker-location-history'),
        authority: 'Fleet Tracker Location History',
        lazy: FleetTrackerLocationHistory,
      },
      {
        path: 'tracking',
        label: t('routers.manager:fleet-tracker-tracking'),
        authority: 'Fleet Tracker Tracking',
        lazy: FleetTrackerTracking,
      },
    ],
  },
  {
    path: 'rentour',
    label: t('routers.manager:rentour'),
    icon: <CarOutlined />,
    children: [
      {
        path: 'billing',
        label: t('routers.manager:rentour-billing'),
        authority: 'Rentour Billing',
        lazy: RentourBilling,
      },
      {
        path: 'reservation-dashboard',
        label: t('routers.manager:rentour-reservation-dashboard'),
        authority: 'Rentour Reservation Dashboard',
        lazy: RentourReservationDashboard,
      },
      {
        path: 'reservation-management',
        label: t('routers.manager:rentour-reservation-management'),
        authority: 'Rentour Reservation Management',
        lazy: RentourReservationManagement,
        children: [
          {
            path: 'editor/:reservationId',
            label: t('routers.manager:rentour-reservation-management'),
            authority: 'Rentour Reservation Editor',
            lazy: RentourReservationEditor,
            excludeMenuItem: true,
            fallback: true,
          },
          {
            path: 'builder',
            label: t('routers.manager:rentour-reservation-management'),
            authority: 'Rentour Reservation Builder',
            lazy: RentourReservationBuilder,
            excludeMenuItem: true,
            fallback: true,
          },
        ],
      },
      {
        path: 'vehicle-management',
        label: t('routers.manager:rentour-vehicle-management'),
        authority: 'Rentour Vehicle Management',
        lazy: RentourVehicleManagement,
        children: [
          {
            path: 'control/:vehicleId',
            label: t('routers.manager:rentour-vehicle-management'),
            authority: 'Rentour Vehicle Control',
            lazy: RentourVehicleControl,
            excludeMenuItem: true,
            fallback: true,
          },
        ],
      },
    ],
  },
  {
    path: 'pricing',
    label: t('routers.manager:pricing'),
    icon: <UserOutlined />,
    children: [
      {
        path: 'pricing-config',
        label: t('routers.manager:pricing-config'),
        authority: 'Pricing Configuration List',
        lazy: PricingConfigList,
        children: [
          {
            path: 'editor/:pricingId',
            label: t('routers.manager:pricing-config'),
            authority: 'Pricing Configuration Editor',
            lazy: PricingConfigEditor,
            excludeMenuItem: true,
            fallback: true,
          },
          {
            path: 'preview/:pricingId',
            label: t('routers.manager:pricing-config'),
            authority: 'Pricing Configuration Editor',
            lazy: PricingConfigPreview,
            excludeMenuItem: true,
            fallback: true,
            children: [
              {
                path: 'edit-history/:pricingTimelineId',
                label: t('routers.manager:pricing-config'),
                authority: 'Pricing Configuration Editor',
                lazy: PricingConfigHistory,
                excludeMenuItem: true,
                fallback: true,
              },
            ],
          },
          {
            path: 'builder',
            label: t('routers.manager:pricing-config'),
            authority: 'Pricing Configuration Builder',
            lazy: PricingConfigBuilder,
            excludeMenuItem: true,
            fallback: true,
          },
          {
            path: 'vehicles/preview/:pricingId',
            label: t('routers.manager:pricing-config'),
            authority: 'Pricing Configuration Editor',
            lazy: PricingConfigVehiclesPreview,
            excludeMenuItem: true,
            fallback: true,
          },
          {
            path: 'vehicles/delete/:pricingId',
            label: t('routers.manager:pricing-config'),
            authority: 'Pricing Configuration Editor',
            lazy: PricingConfigVehiclesDelete,
            excludeMenuItem: true,
            fallback: true,
          },
          {
            path: 'vehicles/add/:pricingId',
            label: t('routers.manager:pricing-config'),
            authority: 'Pricing Configuration Editor',
            lazy: PricingConfigVehiclesAdd,
            excludeMenuItem: true,
            fallback: true,
          },
        ],
      },
    ],
  },
];

export const createManagerRouter = (
  t: TFunction<typeof ROUTERS_MANAGER>,
  i18n: i18n,
): RouteObject[] => [
  {
    path: '/log-in',
    lazy: ManagerLogin,
  },
  { path: '/invitation/:key', lazy: ManagerInvitation },
  { path: '/forget-password', lazy: ManagerForgetPassword },
  { path: '/reset-password/:key', lazy: ManagerResetPassword },
  {
    path: '/org',
    lazy: ManagerWorkspace,
    children: [
      { path: '', element: 'No Organization' },
      {
        path: ':orgId',
        errorElement: <ErrorBoundary />,
        children: [
          ...excludeNoPathAndFlatRoute(createManagerWorkspaceRoutes(t, i18n)),
          { path: 'forbidden', element: 'Forbidden' },
          { path: '*', element: 'Not Found' },
        ],
      },
      { path: '*', element: 'Forbidden' },
    ],
  },
  {
    // TODO: 如果有 TOKEN 應該轉去 No Organization 頁面
    path: '*',
    element: <Navigate to="/log-in" replace />,
  },
];
