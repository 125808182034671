import { Fragment, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Select,
  Radio,
  Typography,
} from 'antd';
import { isNil, isNotNil } from 'ramda';
import { useDebouncedCallback } from 'use-debounce';

import {
  SUPER_ADMIN_SEARCH_MANY_MANAGER_ERROR,
  SUPER_ADMIN_FIND_ONE_MANAGER_ERROR,
  SUPER_ADMIN_CREATE_ONE_MANAGER_SUCCESS,
  SUPER_ADMIN_CREATE_ONE_MANAGER_ERROR,
  SUPER_ADMIN_UPDATE_ONE_MANAGER_SUCCESS,
  SUPER_ADMIN_UPDATE_ONE_MANAGER_ERROR,
  SUPER_ADMIN_UPDATE_ONE_MANAGER_STATUS_SUCCESS,
  SUPER_ADMIN_UPDATE_ONE_MANAGER_STATUS_ERROR,
} from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import {
  ManagerType,
  SearchResultType,
  ManagerEditMode,
  ManagerStatus,
} from '@enums/managers.enum';
import useRouteParams from '@hooks/useRouteParams';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import ContainerSkeleton from '@components/ContainerSkeleton';
import FluidSpace from '@components/FluidSpace';
import EditorHeader from '@components/EditorHeader';
import AffixFormFooter from '@components/AffixFormFooter';
import useSearchManyManager from './hooks/useSearchManyManager';
import useCreateOne from './hooks/useCreateOne';
import useUpdateOne from './hooks/useUpdateOne';
import useFindOne from './hooks/useFindOne';
import useUpdateOneStatus from './hooks/useUpdateOneStatus';
import AuthzSelect from '../components/AuthzSelect';
import { RouteParamsSchema } from './schemas';
import { FormValues } from './interfaces';

const { useForm, Item: FormItem } = Form;
const { Group: RadioGroup, Button: RadioButton } = Radio;
const { Text } = Typography;

const ManagerEditor = () => {
  const routeParams = useRouteParams(RouteParamsSchema);
  const navigate = useEnhancedNavigate();
  const { successNotify, errorNotify } = useGlobalNotification();
  const searchManagers = useSearchManyManager({
    onError: errorNotify(SUPER_ADMIN_SEARCH_MANY_MANAGER_ERROR),
  });
  const createOne = useCreateOne({
    onSuccess: successNotify(SUPER_ADMIN_CREATE_ONE_MANAGER_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_CREATE_ONE_MANAGER_ERROR),
  });
  const updateOne = useUpdateOne({
    onSuccess: successNotify(SUPER_ADMIN_UPDATE_ONE_MANAGER_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_UPDATE_ONE_MANAGER_ERROR),
  });
  const findOne = useFindOne({
    onError: errorNotify(SUPER_ADMIN_FIND_ONE_MANAGER_ERROR),
  });
  const updateOneStatus = useUpdateOneStatus({
    onSuccess: successNotify(SUPER_ADMIN_UPDATE_ONE_MANAGER_STATUS_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_UPDATE_ONE_MANAGER_STATUS_ERROR),
  });
  const [form] = useForm<FormValues>();
  const mgrId = routeParams.get().mgrId;

  const handleGoBackClick = () => {
    navigate(-1, '/super-admins/managers');
  };

  const fetchOneAndSetForm = async (mgrId: string) => {
    form.resetFields();
    const data = await findOne.fetch(mgrId);
    form.setFieldsValue({
      type: data?.type,
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
    });
  };

  const debouncedSearch = useDebouncedCallback((value: string) => {
    searchManagers.fetch(value, isNotNil(mgrId) ? [mgrId] : undefined);
  }, 300);

  const handleSyncOnSearch = async (value: string) => {
    if (value.length < 1) {
      if (debouncedSearch.isPending()) {
        debouncedSearch.cancel();
      }
      searchManagers.clear();
    } else {
      debouncedSearch(value);
    }
  };

  const handleOnSubmit = async () => {
    if (isNil(mgrId)) {
      const mgrId = await createOne.submit(form);
      if (!isNil(mgrId)) {
        navigate(`${mgrId}`, { replace: true });
      }
    } else {
      const result = await updateOne.submit(mgrId, form);
      if (result) fetchOneAndSetForm(mgrId);
    }
  };

  const handleStatusChange = async () => {
    if (isNil(mgrId)) return;
    if (isNil(findOne.data.status)) return;
    const activated = findOne.data.status === ManagerStatus.DEACTIVATED;
    const result = await updateOneStatus.submit(mgrId, { activated });
    if (result) fetchOneAndSetForm(mgrId);
  };

  useEffect(() => {
    if (isNotNil(mgrId)) {
      fetchOneAndSetForm(mgrId);
    }
  }, [mgrId]);

  return (
    <Spin spinning={createOne.loading}>
      <FluidSpace direction="vertical" size="large">
        <EditorHeader
          title={`${isNotNil(mgrId) ? 'Edit' : 'Add'} Manager`}
          subTitle={isNotNil(mgrId) ? `ID: ${mgrId}` : undefined}
          extra={
            <Button
              color={
                findOne.data.status === ManagerStatus.ACTIVATED
                  ? 'danger'
                  : 'primary'
              }
              variant="outlined"
              disabled={
                isNil(findOne.data.status) ||
                ![ManagerStatus.ACTIVATED, ManagerStatus.DEACTIVATED].includes(
                  findOne.data.status,
                )
              }
              onClick={handleStatusChange}
            >
              {isNotNil(findOne.data.status) &&
              findOne.data.status === ManagerStatus.ACTIVATED
                ? 'Deactivate'
                : 'Activate'}
            </Button>
          }
          onGoBack={handleGoBackClick}
        />
        <ContainerSkeleton
          loading={
            findOne.loading || updateOne.loading || updateOneStatus.loading
          }
        >
          <Row gutter={[24, 24]}>
            <Col span={24} md={18} lg={16} xl={12} xxl={8}>
              <Form<FormValues>
                form={form}
                layout="vertical"
                initialValues={{
                  type: ManagerType.REALITY,
                }}
              >
                <FormItem
                  label="Type"
                  name="type"
                  hidden={isNotNil(mgrId)}
                  rules={[{ required: true }]}
                >
                  <RadioGroup buttonStyle="solid">
                    <RadioButton value={ManagerType.REALITY}>
                      Reality
                    </RadioButton>
                    <RadioButton value={ManagerType.BOILERPLATE}>
                      Boilerplate
                    </RadioButton>
                  </RadioGroup>
                </FormItem>
                <FormItem label="Name" name="name" rules={[{ required: true }]}>
                  <Input />
                </FormItem>
                <FormItem<FormValues> noStyle dependencies={['type']}>
                  {(form) => {
                    const type = form.getFieldValue('type');
                    return type === ManagerType.REALITY ? (
                      <Fragment>
                        <FormItem
                          label="Email"
                          name="email"
                          rules={[{ required: true }, { type: 'email' }]}
                        >
                          <Input type="email" />
                        </FormItem>
                        <FormItem label="Phone" name="phone">
                          <Input type="tel" />
                        </FormItem>
                      </Fragment>
                    ) : null;
                  }}
                </FormItem>
              </Form>
            </Col>
            <Col span={24} xxl={16}>
              <Form<FormValues>
                form={form}
                layout="vertical"
                initialValues={{
                  authz: {
                    mode: ManagerEditMode.MANUAL,
                  },
                }}
              >
                <FormItem label="Mode" name={['authz', 'mode']}>
                  <RadioGroup buttonStyle="solid">
                    <RadioButton value={ManagerEditMode.MANUAL}>
                      Manual
                    </RadioButton>
                    <RadioButton value={ManagerEditMode.SYNC}>Sync</RadioButton>
                  </RadioGroup>
                </FormItem>
                <FormItem noStyle dependencies={[['authz', 'mode']]}>
                  {(form) => {
                    const mode = form.getFieldValue(['authz', 'mode']);
                    return mode === ManagerEditMode.MANUAL ? (
                      <Fragment>
                        <FormItem label="Authz" name={['authz', 'items']}>
                          <AuthzSelect
                            source={findOne.data.authzs?.map(
                              ({ org, roles }) => ({
                                org,
                                roleIds: roles.map((role) => role.id),
                              }),
                            )}
                          />
                        </FormItem>
                      </Fragment>
                    ) : (
                      <Row gutter={[24, 24]}>
                        <Col span={24} md={18} lg={16} xl={12} xxl={12}>
                          <FormItem
                            label="Sync"
                            name={['authz', 'syncId']}
                            rules={[{ required: true }]}
                          >
                            <Select
                              showSearch
                              allowClear
                              loading={searchManagers.loading}
                              filterOption={false}
                              notFoundContent={null}
                              options={searchManagers.data.map(
                                ({ id, name, type }) => ({
                                  value: id,
                                  label: (
                                    <FluidSpace size="small">
                                      <Text>{name}</Text>
                                      {type ===
                                        SearchResultType.BOILERPLATE && (
                                        <Text type="secondary">
                                          [Boilerplate]
                                        </Text>
                                      )}
                                    </FluidSpace>
                                  ),
                                }),
                              )}
                              onSearch={handleSyncOnSearch}
                            />
                          </FormItem>
                        </Col>
                      </Row>
                    );
                  }}
                </FormItem>
              </Form>
            </Col>
          </Row>
          <AffixFormFooter>
            <FormItem noStyle>
              <Space>
                <Button type="primary" onClick={handleOnSubmit}>
                  Submit
                </Button>
                <Button onClick={handleGoBackClick}>Cancel</Button>
              </Space>
            </FormItem>
          </AffixFormFooter>
        </ContainerSkeleton>
      </FluidSpace>
    </Spin>
  );
};

export default ManagerEditor;
