import { useState, useMemo, Fragment, useEffect, useRef } from 'react';
import {
  Splitter,
  Select,
  List,
  Checkbox,
  Typography,
  Skeleton,
  Button,
  Divider,
  Empty,
  Flex,
  Badge,
  theme,
  Space,
} from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import { difference, isEmpty, isNil, isNotNil, not } from 'ramda';
import { DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';

import {
  SUPER_ADMIN_SEARCH_MANY_ORGANIZATION_ERROR,
  SUPER_ADMIN_FETCH_ONE_ORGANIZATION_CLUSTER_ERROR,
  SUPER_ADMIN_FETCH_ONE_ROLES_PERMISSIONS_ERROR,
} from '@constants/notifications';
import { SearchScope } from '@enums/organizations.enum';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import FluidSpace from '@components/FluidSpace';
import Link from '@components/Link';
import useSearchManyOrg from './hooks/useSearchManyOrg';
import useFindOrgCluster from './hooks/useFindOrgCluster';
import useRolesPermissions from './hooks/useRolesPermissions';
import { AuthzSelectProps, AuthzValue, SourceValue } from './interfaces';
import { StyledListItem } from './styleds';

const { Panel } = Splitter;
const { Group: CheckboxGroup } = Checkbox;
const { Option: SelectOption } = Select;
const { Text, Title } = Typography;
const { useToken } = theme;

const getNextItems = (
  items: AuthzValue[] | null | undefined,
  itemIndex: number | undefined,
  item: AuthzValue | null,
) => {
  if (isNotNil(item)) {
    if (isNotNil(items)) {
      if (isNotNil(itemIndex)) {
        return [
          ...items.slice(0, itemIndex),
          item,
          ...items.slice(itemIndex + 1),
        ];
      } else {
        return [...items, item];
      }
    } else {
      return [item];
    }
  } else {
    if (isNotNil(items) && isNotNil(itemIndex)) {
      return [...items.slice(0, itemIndex), ...items.slice(itemIndex + 1)];
    } else {
      return items ?? null;
    }
  }
};

const AuthzSelect = (props: AuthzSelectProps = {}) => {
  const { value, source, isValueAdjustWithSource = false, onChange } = props;
  const { token } = useToken();
  const { errorNotify } = useGlobalNotification();
  const search = useSearchManyOrg({
    onError: errorNotify(SUPER_ADMIN_SEARCH_MANY_ORGANIZATION_ERROR),
  });
  const cluster = useFindOrgCluster({
    onError: errorNotify(SUPER_ADMIN_FETCH_ONE_ORGANIZATION_CLUSTER_ERROR),
  });
  const roles = useRolesPermissions({
    onError: errorNotify(SUPER_ADMIN_FETCH_ONE_ROLES_PERMISSIONS_ERROR),
  });
  const [data, setData] = useState<SourceValue[] | undefined>();
  const [searchText, setSearchText] = useState<string>('');
  const [targetIndex, setTargetIndex] = useState<number>();
  const [page, setPage] = useState<number>(1);
  const dataMap = useMemo(
    () =>
      data?.reduce<{ [key: string]: SourceValue }>((result, item) => {
        result[item.org.id] = item;
        return result;
      }, {}) || {},
    [data],
  );
  const sourceLength = useRef(source?.length || 0);
  const newDataLastIndex = useMemo(
    () => (data?.length || 0) - sourceLength.current,
    [data],
  );
  const addMap = useMemo(
    () =>
      value?.add?.reduce<{ [key: string]: number }>((result, item, index) => {
        result[item.orgId] = index;
        return result;
      }, {}) || {},
    [value],
  );
  const removeMap = useMemo(
    () =>
      value?.remove?.reduce<{ [key: string]: number }>(
        (result, item, index) => {
          if (isNil(item.roleIds) || isEmpty(item.roleIds)) {
            result[item.orgId] = index;
          }
          return result;
        },
        {},
      ) || {},
    [value],
  );
  const checkAll =
    isNotNil(targetIndex) && isNotNil(data)
      ? (data[targetIndex].roleIds?.length || 0) === roles.data.length
      : false;
  const indeterminate =
    isNotNil(targetIndex) && isNotNil(data)
      ? (data[targetIndex].roleIds?.length || 0) > 0 &&
        (data[targetIndex].roleIds?.length || 0) < roles.data.length
      : false;

  const searchAuthz = useDebouncedCallback((text: string) => {
    const regexp = new RegExp(`^${text}`, 'i');
    const excludes = data?.filter((item) => regexp.test(`${item.org.name}`));
    search.fetch(
      text,
      excludes?.map((item) => item.org.id),
    );
  }, 300);

  const handleOnSearch = (text: string) => {
    setSearchText(text);
    if (text.length < 1) {
      if (searchAuthz.isPending()) {
        searchAuthz.cancel();
      }
      search.clear();
    } else {
      searchAuthz(text);
    }
  };

  const handleSelectChange = async (orgId: string) => {
    try {
      const result = search.data.find((item) => orgId === item.id);
      if (isNil(result)) return;
      if (result.type === SearchScope.CLUSTER) {
        const data = await cluster.fetch(result.id);
        if (isNil(data)) return;
        const { members } = data;
        const [addAuthz, addSource] = members.reduce<
          [AuthzValue[], SourceValue[]]
        >(
          (result, member) => {
            if (isNil(dataMap[member.id])) {
              result[0].push({ orgId: member.id, roleIds: null });
              result[1].push({ org: member, roleIds: null });
            }
            return result;
          },
          [[], []],
        );
        setData((prev) => [...addSource, ...(prev || [])]);
        onChange?.({
          add: isNotNil(value?.add)
            ? [...addAuthz, ...value.add]
            : [...addAuthz],
          remove: value?.remove || null,
        });
      } else {
        const [addAuthz, addSource] = [
          { orgId: result.id, roleIds: null },
          { org: result, roleIds: null },
        ];
        setData((prev) => [addSource, ...(prev || [])]);
        onChange?.({
          add: isNotNil(value?.add) ? [addAuthz, ...value.add] : [addAuthz],
          remove: value?.remove || null,
        });
      }
    } finally {
      setSearchText('');
      search.clear();
    }
  };

  const handleRemoveClick = (orgId: string, index: number) => {
    try {
      if (index < newDataLastIndex) {
        setData((prev) =>
          isNotNil(prev)
            ? [...prev.slice(0, index), ...prev.slice(index + 1)]
            : prev,
        );
        onChange?.({
          add: isNotNil(value?.add)
            ? [...value.add.slice(0, index), ...value.add.slice(index + 1)]
            : [],
          remove: value?.remove || null,
        });
      } else {
        onChange?.({
          add: value?.add || null,
          remove: isNotNil(value?.remove)
            ? [...value.remove, { orgId, roleIds: null }]
            : [{ orgId, roleIds: null }],
        });
      }
    } finally {
      roles.clear();
      setTargetIndex(undefined);
    }
  };

  const handleUndoClick = (removeIndex: number) => {
    onChange?.({
      add: value?.add || null,
      remove: isNotNil(value?.remove)
        ? [
            ...value.remove.slice(0, removeIndex),
            ...value.remove.slice(removeIndex + 1),
          ]
        : null,
    });
  };

  const handleListItemClick = (orgId: string, index: number) => {
    if (isNotNil(removeMap[orgId])) return;
    setTargetIndex(index);
    roles.fetch(orgId);
  };

  const handleCheckboxGroupChange = (roleIds: string[]) => {
    if (isNil(targetIndex) || isNil(data)) return;

    if (targetIndex < newDataLastIndex) {
      const currData = data[targetIndex];
      const addIndex = addMap[currData.org.id];
      onChange?.({
        add: isNotNil(value?.add)
          ? [
              ...value.add.slice(0, addIndex),
              {
                orgId: value.add[addIndex].orgId,
                roleIds: isEmpty(roleIds) ? null : roleIds,
              },
              ...value.add.slice(addIndex + 1),
            ]
          : null,
        remove: value?.remove || null,
      });
    } else {
      const index = targetIndex - newDataLastIndex;
      const data = source?.[index];
      if (isNil(data)) return;

      const orgId = data.org.id;
      const addIndex = addMap[orgId] ?? undefined;
      const removeIndex = removeMap[orgId] ?? undefined;
      const addRoleIds = difference(roleIds, data.roleIds || []);
      const removeRoleIds = difference(data.roleIds || [], roleIds);
      const addItem = isEmpty(addRoleIds)
        ? null
        : {
            orgId,
            roleIds: addRoleIds.length > 0 ? addRoleIds : null,
          };
      const removeItem = isEmpty(removeRoleIds)
        ? null
        : {
            orgId,
            roleIds: removeRoleIds.length > 0 ? removeRoleIds : null,
          };

      onChange?.({
        add: getNextItems(value?.add, addIndex, addItem),
        remove: getNextItems(value?.remove, removeIndex, removeItem),
      });
    }

    setData((prev) =>
      isNotNil(prev)
        ? [
            ...prev.slice(0, targetIndex),
            {
              org: prev[targetIndex].org,
              roleIds: isEmpty(roleIds) ? null : roleIds,
            },
            ...prev.slice(targetIndex + 1),
          ]
        : prev,
    );
  };

  const handleRemoveAllClick = () => {
    if (page !== 1) setPage(1);
    if (isNotNil(targetIndex)) setTargetIndex(undefined);
    if (not(isEmpty(roles.data))) roles.clear();
    setData(source);
    onChange?.({
      add: null,
      remove:
        source?.map(({ org }) => ({ orgId: org.id, roleIds: null })) ?? null,
    });
  };

  const handleUndoAllClick = () => {
    onChange?.({
      add: value?.add ?? null,
      remove:
        value?.remove?.filter(
          (item) => isNotNil(item.roleIds) && not(isEmpty(item.roleIds)),
        ) ?? null,
    });
  };

  useEffect(() => {
    if (page !== 1) setPage(1);
    if (isNotNil(targetIndex)) setTargetIndex(undefined);
    if (not(isEmpty(roles.data))) roles.clear();

    sourceLength.current = source?.length || 0;
    if (isValueAdjustWithSource) {
      let nextData = [...(source ?? [])];
      const nextSourceMap = (source ?? []).reduce<Record<string, number>>(
        (result, item, index) => {
          result[item.org.id] = index;
          return result;
        },
        {},
      );
      const currNewData = (data ?? []).slice(0, newDataLastIndex);
      currNewData.forEach((item) => {
        const nextIndex = nextSourceMap[item.org.id];
        if (isNil(nextIndex)) {
          nextData = [item, ...nextData];
        }
      });

      const nextDataMap = nextData.reduce<Record<string, number>>(
        (result, item, index) => {
          result[item.org.id] = index;
          return result;
        },
        {},
      );

      const nextValueAdd = value?.add?.filter((item) => {
        return isNotNil(nextDataMap[item.orgId]);
      });
      const nextValueRemove = value?.remove?.filter((item) => {
        return isNotNil(nextDataMap[item.orgId]);
      });

      setData(nextData);
      onChange?.({
        add: nextValueAdd || null,
        remove: nextValueRemove || null,
      });
    } else {
      setData(source);
      onChange?.(undefined);
    }
  }, [source]);

  return (
    <Splitter>
      <Panel
        defaultSize="50%"
        min="30%"
        max="70%"
        style={{ paddingRight: 16, overflow: 'hidden' }}
      >
        <FluidSpace direction="vertical">
          <Select
            loading={search.loading || cluster.loading}
            showSearch
            suffixIcon={null}
            filterOption={false}
            notFoundContent={null}
            searchValue={searchText}
            onSearch={handleOnSearch}
            value={null}
            onChange={handleSelectChange}
            onBlur={search.clear}
            onDropdownVisibleChange={search.clear}
          >
            {search.data?.map(({ id, name, type }) => (
              <SelectOption key={id} value={id}>
                <FluidSpace size="small">
                  <Text>{name}</Text>
                  {type === SearchScope.CLUSTER && (
                    <Text type="secondary">[Cluster]</Text>
                  )}
                </FluidSpace>
              </SelectOption>
            ))}
          </Select>

          <List
            itemLayout="horizontal"
            dataSource={data}
            pagination={{
              align: 'end',
              total: data?.length,
              current: page,
              onChange: (page) => setPage(page),
              showTotal() {
                return (
                  <Space>
                    <Button onClick={handleRemoveAllClick}>Remove All</Button>
                    <Button onClick={handleUndoAllClick}>Undo All</Button>
                  </Space>
                );
              },
            }}
            renderItem={({ org, roleIds }, index) => {
              const realIndex = index + (page - 1) * 10;
              return (
                <StyledListItem
                  active={targetIndex === realIndex}
                  clickable={isNil(removeMap[org.id])}
                  onClick={() => handleListItemClick(org.id, realIndex)}
                  actions={
                    !cluster.loading
                      ? [
                          <FluidSpace size="large">
                            <Badge
                              color={
                                isNotNil(roleIds) && not(isEmpty(roleIds))
                                  ? token.colorInfoHover
                                  : token.colorTextPlaceholder
                              }
                              showZero
                              count={isNotNil(roleIds) ? roleIds.length : 0}
                            />
                            <Button
                              type="link"
                              icon={
                                isNotNil(removeMap[org.id]) ? (
                                  <UndoOutlined />
                                ) : (
                                  <DeleteOutlined />
                                )
                              }
                              onClick={(event) => {
                                event.stopPropagation();
                                if (isNotNil(removeMap[org.id])) {
                                  handleUndoClick(removeMap[org.id]);
                                } else {
                                  handleRemoveClick(org.id, realIndex);
                                }
                              }}
                            />
                          </FluidSpace>,
                        ]
                      : undefined
                  }
                >
                  <Skeleton
                    loading={cluster.loading}
                    active
                    title={false}
                    paragraph={{ rows: 2 }}
                  >
                    <List.Item.Meta
                      title={
                        <Space>
                          <Link
                            navigate={{
                              to: `../organizations/editor/${org.id}`,
                            }}
                          >
                            <SelectOutlined />
                          </Link>
                          <Text>{org.name}</Text>
                        </Space>
                      }
                    />
                  </Skeleton>
                </StyledListItem>
              );
            }}
          />
        </FluidSpace>
      </Panel>
      <Panel
        defaultSize="50%"
        min="30%"
        max="70%"
        style={{ paddingLeft: 16, overflow: 'hidden' }}
      >
        <Skeleton loading={roles.loading} paragraph={{ rows: 8 }}>
          {isNotNil(targetIndex) && isNotNil(data) ? (
            <Fragment>
              <Title level={4}>{data[targetIndex].org.name}</Title>
              <Divider />
              <FluidSpace direction="vertical">
                <Checkbox
                  indeterminate={indeterminate}
                  checked={checkAll}
                  onChange={() =>
                    handleCheckboxGroupChange(
                      checkAll ? [] : roles.data.map(({ id }) => id),
                    )
                  }
                >
                  All
                </Checkbox>
                <CheckboxGroup
                  value={data[targetIndex].roleIds || undefined}
                  onChange={handleCheckboxGroupChange}
                >
                  <FluidSpace direction="vertical">
                    {roles.data.map(({ id, name }) => (
                      <Checkbox key={id} value={id}>
                        {name}
                      </Checkbox>
                    ))}
                  </FluidSpace>
                </CheckboxGroup>
              </FluidSpace>
            </Fragment>
          ) : (
            <Flex style={{ height: '100%' }} justify="center" align="center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Flex>
          )}
        </Skeleton>
      </Panel>
    </Splitter>
  );
};

export default AuthzSelect;
