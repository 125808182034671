import { getManyUserRole, getOneUserRole } from '@/apis/hermes-proxy/pricing';
import { create } from 'zustand';

import * as APIs from '@interfaces/apis';

export interface UserRoleState {
  userRoles: APIs.HP_Pricing.UserRole[];
  userRoleMap: Record<string, APIs.HP_Pricing.UserRole>;
  userRoleOptions: { value: number; label: string }[];
  loading: boolean;
}

export interface UserRoleAction {
  init: (
    query?: APIs.Utils.OptionalPagination<APIs.HP_Pricing.FindManyUserRoleQuery>,
  ) => Promise<void>;
  fetchUserRoleById: (id: string) => Promise<APIs.HP_Pricing.UserRole>;
}

export const useUserRoleStore = create<UserRoleState & UserRoleAction>(
  (set, get) => ({
    userRoles: [],
    userRoleMap: {},
    userRoleOptions: [],
    loading: false,
    async init(
      query = {
        size: 100,
        page: 1,
      },
      force = false,
    ) {
      if (!force && get().userRoles.length) return;

      if (get().loading) return;

      set({
        loading: true,
      });

      const [data] = await getManyUserRole(query);

      set({
        loading: false,
        userRoles: data,
        userRoleMap: data.reduce(
          (acc, userRole) => ({
            ...acc,
            [userRole.id]: userRole,
          }),
          {},
        ),
        userRoleOptions: data.map((role) => ({
          value: Number(role.id),
          label: role.name,
        })),
      });
    },
    async fetchUserRoleById(id: string) {
      const data = await getOneUserRole(id);
      set({
        userRoleMap: {
          ...get().userRoleMap,
          [id]: data,
        },
      });

      return data;
    },
  }),
);
