import { i18n, TFunction } from 'i18next';
import { createBrowserRouter } from 'react-router-dom';

import { ROUTERS_MANAGER, ROUTERS_SUPER_ADMIN } from '@i18n/namespaces';
import { useHistoryStore } from '@stores';
import { createManagerRouter } from './manager.router';
import { createSuperAdminRouter } from './super-admin.router';

export const createRouter = (
  t: TFunction<[typeof ROUTERS_MANAGER, typeof ROUTERS_SUPER_ADMIN]>,
  i18n: i18n,
) => {
  const router = createBrowserRouter([
    ...createSuperAdminRouter(t, i18n),
    ...createManagerRouter(t, i18n),
  ]);

  router.subscribe(({ location }) => {
    useHistoryStore.setState(location);
  });

  return router;
};
