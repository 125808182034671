import { formatResponse, formatResponseWithMeta } from '@/utils/api.util';
import { HP_Pricing, Utils } from '@interfaces/apis';
import { managerAxios } from '../axios';

export const getManyPricing = async (
  query: Utils.OptionalPagination<HP_Pricing.FindManyPricingQuery>,
) => {
  return managerAxios
    .get<Utils.WeMoReplyWithMeta<HP_Pricing.FindManyPricingResponse>>(
      'hermes-proxy/pricing',
      { params: query },
    )
    .then(formatResponseWithMeta);
};

export const getOnePricing = async (id: string) => {
  return managerAxios
    .get<Utils.WeMoReply<HP_Pricing.FindOnePricingResponse>>(
      `hermes-proxy/pricing/${id}`,
    )
    .then(formatResponse);
};

export const postOnePricing = async (data: HP_Pricing.CreateOnePricingBody) =>
  managerAxios
    .post<Utils.WeMoReply<HP_Pricing.CreateOnePricingResponse>>(
      'hermes-proxy/pricing',
      data,
    )
    .then(formatResponse);

export const patchOnePricing = async (
  id: string,
  data: HP_Pricing.UpdateOnePricingBody,
) =>
  managerAxios.patch<Utils.WeMoReply<HP_Pricing.UpdateOnePricingResponse>>(
    `hermes-proxy/pricing/${id}`,
    data,
  );

export const getOneTagAction = async (
  query: HP_Pricing.FindOneTagActionQuery,
) => {
  return managerAxios
    .get<Utils.WeMoReply<HP_Pricing.FindOneTagActionResponse>>(
      'hermes-proxy/tagActions',
      {
        params: query,
      },
    )
    .then(formatResponse);
};

export const getManyVehicleTag = async (
  query: HP_Pricing.FindManyVehicleTagQuery,
) => {
  return managerAxios
    .get<Utils.WeMoReply<HP_Pricing.FindManyVehicleTagResponse>>(
      'hermes-proxy/vehicleTags',
      { params: query },
    )
    .then(formatResponse);
};

export const postManyVehicleTags = async (
  data: HP_Pricing.CreateManyVehicleTagBody,
) => {
  await managerAxios.post('hermes-proxy/vehicleTags', data);
};

export const deleteManyVehicleTags = async (
  data: HP_Pricing.DeleteManyVehicleTagBody,
) => {
  await managerAxios.delete('hermes-proxy/vehicleTags', {
    data,
  });
};

export const getManyUserRole = async (
  query: Utils.OptionalPagination<HP_Pricing.FindManyUserRoleQuery>,
) => {
  return managerAxios
    .get<Utils.WeMoReplyWithMeta<HP_Pricing.UserRole>>(
      'hermes-proxy/userRoles',
      { params: query },
    )
    .then(formatResponseWithMeta);
};

export const getOneUserRole = async (id: string) => {
  return managerAxios
    .get<Utils.WeMoReply<HP_Pricing.UserRole>>(`hermes-proxy/userRoles/${id}`)
    .then(formatResponse);
};

export const getOnePricingTimelineLog = async (
  query: HP_Pricing.FindOnePricingTimelineLogQuery,
) => {
  return managerAxios
    .get<Utils.WeMoReply<HP_Pricing.PricingTimelineLog[]>>(
      'hermes-proxy/pricingTimelineLogs',
      { params: query },
    )
    .then(formatResponse);
};

export const getOnePricingTimeline = async (pricingTimelineId: string) => {
  return managerAxios
    .get<Utils.WeMoReply<HP_Pricing.FindOnePricingTimelineResponse>>(
      `hermes-proxy/pricingTimeline/${pricingTimelineId}`,
    )
    .then(formatResponse);
};
